import React from 'react'
import { FaLinkedin, FaGithub } from 'react-icons/fa'

export default [
  {
    icon: <FaLinkedin />,
    label: 'Kate Mills on Linkedin',
    url: 'https://www.linkedin.com/in/katescottmills/',
  },
  {
    icon: <FaGithub />,
    label: 'Kate Mills on GitHub',
    url: 'https://github.com/kate-mills',
  },
]
